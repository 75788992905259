* {
  padding: 0;
  margin: 0;
  line-height: 1;
}
@font-face {
  font-family: regular;
  src: url(../fonts/Maven_Pro/static/MavenPro-Regular.ttf);
}
@font-face {
  font-family: medium;
  src: url(../fonts/Maven_Pro/static/MavenPro-Medium.ttf);
}
@font-face {
  font-family: bold;
  src: url(../fonts/Maven_Pro/static/MavenPro-Bold.ttf);
}
.medium {
  font-weight: 500;
}
.bold,
.medium {
  font-family: Maven Pro, sans-serif;
}
.bold {
  font-weight: 600;
}
p {
  line-height: 1.5;
}
a {
  text-decoration: none;
  transition: all 0.2s ease;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: bold;
}
body {
  font-family: Maven Pro, sans-serif;
  color: #555;
}
.divider {
  width: 100%;
  font-size: 0;
  line-height: 0;
  margin-top: -1px;
}
button {
  cursor: pointer;
  transition: all 0.2s ease;
}
.center_title {
  font-family: bold;
  text-align: center;
  margin-bottom: 35px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.center_title h1,
.center_title h2,
.center_title h3,
.center_title h4,
.center_title h5,
.center_title h6 {
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  margin: 0;
}
.center_title h1:after,
.center_title h2:after,
.center_title h3:after,
.center_title h4:after,
.center_title h5:after,
.center_title h6:after {
  content: '';
  display: inline-block;
  height: 3px;
  background: #e31838;
  width: 80%;
  margin: auto;
  border-radius: 100px;
}
#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 85px;
  align-items: center;
  display: flex;
  z-index: 99;
}
.header_main {
  background: #fff;
  box-shadow: 0 6px 11px 0 rgb(0 0 0/12%);
}
.haeder_menu {
  position: fixed;
  right: -600px;
  top: 0;
  bottom: 0;
  background: rgb(255 255 255/97%);
  width: 300px;
  box-shadow: 0 0 9px 0 rgb(0 0 0/58%);
  transition: all 0.3s ease;
  z-index: 1;
}
#header .social_network_menu {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  position: absolute;
  bottom: 100px;
  z-index: 9;
}
#header .social_network_menu a {
  width: 35px;
  border-bottom: none;
  line-height: 1;
  display: inline-block;
}
#header .social_network_menu a:hover {
  background: transparent;
}
.social_network_menu img {
  width: 100%;
  opacity: 0.9;
  border-radius: 100px;
  transform: scale(1);
  transition: all 0.3s ease;
}
.overlay_menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  background: rgb(0 0 0/40%);
}
.haeder_menu.open {
  right: 0;
}
.haeder_menu_content {
  margin-top: 60px;
}
.haeder_menu .wave_img {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
}
.haeder_menu a {
  color: #555;
  display: block;
  line-height: 54px;
  text-align: center;
  font-family: medium;
  border-bottom: 1px solid #eaeaea;
  font-size: 17px;
}
.haeder_menu a:hover {
  background: #ececec;
}
.haeder_menu_content {
  border-top: 1px solid #eaeaea;
}
.loader_holder {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 99;
  box-shadow: 0 5px 8px 0 rgb(0 0 0/10%);
}
.loader_content,
.loader_holder {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader_content {
  flex-wrap: wrap;
  width: 200px;
}
.loader_content img {
  margin-bottom: 15px;
  width: 50px;
}
.progressbar {
  width: 100%;
  z-index: 99;
  margin-top: 1rem;
  height: 4px;
  width: 10rem;
  border-radius: 100px;
  background: #b3b3b3;
}
.progressbar:after {
  content: '';
  width: 50px;
  height: 4px;
  background: #ec3754;
  display: block;
  border-radius: 100px;
  -webkit-animation: loader 1.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  animation: loader 1.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}
@-webkit-keyframes loader {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(7rem);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes loader {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(7rem);
  }
  to {
    transform: translateX(0);
  }
}
.haeder_menu .close_icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}
.haeder_menu .close_icon img {
  width: 24px;
}
.flag_menu_icon {
  display: flex;
  align-items: center;
}
.langs_header {
  margin-right: 25px;
}
.langs_header a:first-child {
  margin-right: 10px;
}
.langs_header img {
  width: 25px;
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.23137254901960785);
  transform: scale(1);
  transition: all 0.32s ease;
}
.langs_header a:hover img {
  transform: scale(1.1);
}
.menu_icon img {
  width: 40px;
}
#header .content_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.home_header {
  background: rgb(255 255 255);
  transition: all 0.2s ease;
  box-shadow: 0 6px 11px 0 rgb(0 0 0/0);
}
.home_header.fillHeader {
  box-shadow: 0 6px 11px 0 rgb(0 0 0/12%);
}
.content_box {
  margin: auto;
  max-width: 1200px;
}
.slide_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home_main_slider {
  display: flex !important;
  align-items: center;
  height: 100vh;
  max-height: 950px;
  background: url(../images/slider-bg.png);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: 45%;
  margin-bottom: 0 !important;
  z-index: 1;
}
.slick-dots li button:before {
  font-size: 12px !important;
  color: #fd4f6a !important;
}
.main_slider {
  position: relative;
}
.main_slider .divider {
  position: absolute;
  bottom: 0;
  margin-bottom: -3px;
}
.main_slider .slick-slide img {
  opacity: 0.6;
}
.main_slider .slick-slide.slick-active img {
  opacity: 1;
  -webkit-animation: fadeInLeft 1.2s ease-in-out 0s forwards;
  animation: fadeInLeft 1.2s ease-in-out 0s forwards;
}
.main_slider .slick-slide .home_main_slider_text {
  opacity: 0.6;
}
.main_slider .slick-slide.slick-active .home_main_slider_text {
  opacity: 1;
  -webkit-animation: fadeInUp 1.5s ease-in-out 0s forwards;
  animation: fadeInUp 1.5s ease-in-out 0s forwards;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  70% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  70% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translatex(170px);
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translatex(0);
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translatex(170px);
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translatex(0);
    transform: none;
  }
}
.home_main_slider .slick-dots {
  text-align: left;
  bottom: 10%;
}
.home_main_slider img {
  margin-right: 60px;
  max-width: 455px;
}
.home_main_slider_text {
  width: 50%;
  padding-left: 20px;
}
.home_main_slider_text h3 {
  margin-bottom: 20px;
}
.home_main_slider_text a {
  margin-top: 20px;
  background: #e31838;
  border: 2px solid #fff;
  box-shadow: 0 3px 9px 2px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
  color: #fff;
  line-height: 43px;
  box-shadow: 0 3px 9px 2px 12% #000;
  display: inline-block;
  font-family: bold;
  padding: 0 31px;
}
.home_main_slider_text a:hover {
  background: #ce1431;
}
.about_us_home {
  position: relative;
}
.bg_flowers {
  top: -35px;
  right: 0;
  width: 100%;
  position: absolute;
}
.about_us_home_content {
  position: relative;
  z-index: 1;
  background: radial-gradient(50% 50% at 50% 50%, #e23a55 0, #e31838 100%);
  color: #fff;
}
.about_us_home .content_box {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 160px 0 45px;
}
.about_us_home iframe {
  border-radius: 5px;
  border: 2px solid #fff;
}
.about_us_home a {
  display: inline-block;
  font-family: medium;
  background: #9f0d24;
  border-radius: 100px;
  color: #fff;
  line-height: 41px;
  padding: 0 30px;
  margin-top: 20px;
}
.about_us_home a:hover {
  background: #ff647d;
}
.about_us_home_text {
  max-width: 500px;
}
.about_us_home_text h3 {
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.private_labiling_home {
  position: relative;
  background: url(../images/bg.svg);
  background-size: cover;
}
.private_labiling_home .divider {
  position: relative;
  z-index: 9;
}
.private_labiling_home #privateLabelVideo {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.private_labiling_home .content_box {
  min-height: 500px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  color: #fff;
}
.private_labiling_home_text {
  max-width: 500px;
}
.private_labiling_home_text a {
  display: inline-block;
  font-family: medium;
  background: #f24c66;
  border-radius: 100px;
  color: #fff;
  line-height: 41px;
  padding: 0 30px;
  margin-top: 20px;
}
.more_about_us_home_btn2 a {
  display: none !important;
}
.private_labiling_home_text a:hover {
  background: #bf2940;
}
.private_labiling_home_text h3 {
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.products_slider_home {
  padding: 55px 0;
}
.products_slider_home .text {
  width: 100%;
}
.products_slider_home .center_title {
  margin-bottom: 10px;
}
.products_slider_home_content .slick-slide {
  display: flex;
  align-items: center;
  padding: 20px;
}
.products_slider_home_content h3 {
  margin-bottom: 0;
  white-space: nowrap;
  font-family: medium;
  text-transform: uppercase;
  font-size: 17px;
  letter-spacing: 0.5px;
  color: #333;
}
.products_slider_home_content #lid {
  -webkit-animation: blink 2.4s infinite;
  animation: blink 2.4s infinite;
}
.products_slider_home_content #eye {
  -webkit-animation: squeeze 2.4s infinite;
  animation: squeeze 2.4s infinite;
}
@-webkit-keyframes blink {
  90% {
    transform: none;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(15px) scaleY(0);
  }
  to {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes blink {
  90% {
    transform: none;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(15px) scaleY(0);
  }
  to {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes squeeze {
  90% {
    transform: none;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(3px) scaleY(0.8);
  }
  to {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes squeeze {
  90% {
    transform: none;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(3px) scaleY(0.8);
  }
  to {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.products_slider_home_content .product_btn {
  position: relative;
  text-align: right;
}
.products_slider_home_content .product_btn a {
  margin-top: 20px;
  background: #ff4966;
  border: 2px solid #fff;
  box-shadow: 0 3px 9px 2px rgb(0 0 0/12%);
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-family: bold;
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  margin-left: auto;
  transform: scale(1);
  transition: all 0.32s ease;
}
.products_slider_home_content .product_btn a:hover {
  transform: scale(1.1);
}
.products_slider_home_content .title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.products_slider_home_content .title span {
  background: #dcdcdc;
  width: 100%;
  height: 1px;
  display: inline-block;
  border-radius: 100px;
  display: block;
  margin-left: 20px;
}
.products_slider_home img {
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1411764705882353);
  max-width: 240px;
  margin-right: 30px;
  border: 2px solid #fff;
}
.products_slider_home .eye_border {
  display: block;
  height: 1px;
  background: #dcdcdc;
  position: absolute;
  width: calc(100% - 75px);
  top: 50%;
  margin-top: 0.5px;
}
.news_slider_home_content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #fff;
  margin-bottom: 0 !important;
  overflow: hidden;
}
.news_slider_home .bg_overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0 0 0/30%);
}
.news_slider_home_content img {
  width: 100%;
}
.news_slider_home_content .slick-dots {
  bottom: 25px;
  text-align: left;
  padding-left: 5%;
}
.news_slider_home_content .slick-dots li button:before {
  color: #fff !important;
  opacity: 1 !important;
}
.news_slider_home_content li.slick-active button:before {
  color: #fd4f6a !important;
}
.news_slider_home_content .slick-next {
  right: 25px;
  z-index: 1;
}
.news_slider_home_content .slick-prev {
  left: 25px;
  z-index: 1;
}
.news_slider_home_content .slide {
  padding: 160px 0;
  background-size: cover !important;
}
.news_slider_home_content .slide p {
  max-width: 600px;
  margin: auto;
}
.news_slider_home_content a {
  display: inline-block;
  font-family: medium;
  background: rgb(227 24 56/75%);
  border-radius: 100px;
  color: #fff;
  line-height: 41px;
  padding: 0 40px;
  margin-top: 20px;
}
.news_slider_home_content a:hover {
  background: rgb(227 24 56/80%);
}
.home_counter,
.news_slider_home_content .slide_content_news_home {
  position: relative;
  z-index: 1;
}
.home_counter {
  background: url(../images/bg-counter.png);
  background-size: cover;
  background-position: 50%;
  height: 470px;
}
.home_counter .counter_item {
  display: flex;
  width: 250px;
  height: 250px;
  margin: 5px;
  background: #de3953;
  border: 5px solid #fff;
  box-shadow: 2px 4px 5px 6px rgb(0 0 0/15%);
}
.home_counter .counter_item:first-child {
  border-radius: 3px 120px;
}
.home_counter .counter_item:nth-child(2),
.home_counter .counter_item:nth-child(3) {
  border-radius: 120px 3px;
}
.home_counter .counter_item:nth-child(4) {
  border-radius: 3px 120px;
}
.counder_holder {
  display: flex;
  flex-wrap: wrap;
  width: 550px;
  margin-top: -5px;
}
.home_counter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter_logo {
  position: absolute;
  bottom: 35px;
  left: 45px;
}
.counter_item_content {
  margin: auto;
  color: #fff;
}
.counter_item_content > div,
.counter_item_content p {
  width: 100%;
  text-align: center;
}
.counter_item_number {
  font-size: 35px;
  font-family: bold;
}
.footer_main {
  position: relative;
  background: url(../images/bg-footer.png);
  background-size: cover;
  padding-top: 150px;
}
.footer_main .footer_items_holder {
  display: flex;
  justify-content: space-around;
  padding-bottom: 35px;
  border-bottom: 1px solid #dddddd;
}
.footer_item {
  font-size: 17px;
}
.footer_item li {
  list-style: none;
  font-family: medium;
  margin-bottom: 15px;
}
.footer_item li a,
.footer_item {
  color: #7e7e7e;
}
.social_networks_footer img {
  max-width: 30px;
}
.social_networks_footer li a {
  display: flex;
  align-items: center;
}
.social_networks_footer li a span {
  margin-left: 10px;
}
.footer_item h3 {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 18px;
  letter-spacing: 1px;
}
.footer_item h3:after {
  content: '';
  height: 2px;
  display: block;
  background: #e31838;
  border-radius: 100px;
  margin-top: 9px;
  width: 85%;
}
.footer_logo {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 65px;
  padding-top: 10px;
}
.footer_logo img {
  margin-right: 40px;
  opacity: 0.8;
}
.footer_newsletter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
.footer_newsletter > label {
  font-size: 17px;
  font-weight: 500;
  color: #7e7e7e;
  margin-bottom: 10px;
}
.footer_newsletter_input {
  background-color: white;
  border: 1px solid #dddddd;
  border-radius: 6px;
}
.footer_newsletter_input > input {
  border: 0;
  background-color: transparent;
  padding: 0 12px;
  height: 30px;
  border-radius: 6px 0 0 6px;
  font-size: 16px;
  margin: 0;
}
.footer_newsletter_input > input:focus {
  outline: none;
}
.footer_newsletter_input > button {
  border: 0;
  background-color: #e41437;
  color: white;
  padding: 0 12px;
  height: 30px;
  border-radius: 0 6px 6px 0;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}
.copyrights_footer {
  background: rgb(227 24 56/82%);
  color: #fff;
  font-size: 13px;
  text-align: center;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.about_us_video {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
#myVideo {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.divider_1_about {
  display: block;
  position: relative;
  z-index: 2;
  margin-top: -150px;
}
.divider_2_about {
  margin-top: -50px;
  z-index: 22;
  position: relative;
  width: 100%;
}
.about_us_text {
  z-index: 1;
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: url(../images/aboutus-text-1.png);
  background-size: cover;
  margin-top: -55px;
}
.about_us_text .about_us_text_bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.about_us_text .content_box {
  z-index: 1;
  text-align: center;
  max-width: 685px;
}
.about_us_text .content_box p {
  max-width: 1000px;
  color: #777;
  font-size: 17px;
}
.about_us_first_section {
  margin-top: 20px;
}
.about_us_video_logo_holder {
  z-index: 9;
  display: flex;
  align-items: center;
  height: 99vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
}
.about_us_video_logo_holder img {
  width: 140px;
}
.about_us_first_section .title h3,
.about_us_history_section .title h3 {
  display: inline-block;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 17px;
  letter-spacing: 0.6px;
}
.about_us_first_section .title h3:after,
.about_us_history_section .title h3:after {
  content: '';
  display: inline-block;
  width: 85%;
  height: 2px;
  border-radius: 100px;
  background: #e31838;
  margin-top: -10px;
}
.about_us_history_section_item {
  margin-bottom: 35px;
  text-align: center;
}
.about_us_text .content_box img {
  margin-bottom: 25px;
}
.about_us_first_section .content_box {
  display: flex;
  align-items: center;
}
.about_us_first_section iframe {
  border-radius: 7px;
  box-shadow: 0 5px 7px 0 rgb(0 0 0/20%);
}
.about_us_first_section .right {
  padding-top: 50px;
}
.about_us_first_section .left {
  margin-right: 40px;
}
.about_us_first_section .content_box > .left > div:first-child {
  margin-bottom: 20px;
}
.about_us_first_section .content_box p,
.about_us_history_section p {
  margin-bottom: 10px;
  font-size: 15px;
}
.carousel_about_us {
  margin-top: 50px;
}
.carousel_about_us .slick-arrow {
  display: none !important;
}
.single_news_header {
  display: flex;
  align-items: center;
  margin-top: 80px;
  background-size: cover !important;
  background-position: 50% !important;
  height: 60vh;
}
.single_news_header_title {
  background: rgb(255 255 255/90%);
  border-radius: 0 100px 100px 0;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.050980392156862744);
  padding: 30px 150px 30px 30px;
}
.single_news_header_title h1 {
  font-size: 19px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 10px;
}
.single_news_header_title span {
  width: 100px;
  height: 3px;
  background: #e53a55;
  display: inline-block;
  border-radius: 100px;
}
.single_news_content_title {
  margin-top: 60px;
  margin-bottom: 15px;
  font-size: 20px;
}
.single_news_content_title h3:after {
  content: '';
  height: 2px;
  width: 80px;
  background: #e53a55;
  border-radius: 100px;
  display: block;
  margin-top: 9px;
}
.single_news_content p {
  margin-bottom: 15px;
}
.prev_next_news_holder {
  display: flex;
  margin-top: 50px;
}
.prev_next_news_holder > div {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 50%;
  background-size: cover !important;
  background-position: 50% !important;
  cursor: pointer;
}
.prev_next_news_holder .next_news {
  margin-left: 2.5px;
}
.prev_next_news_holder .prev_news {
  margin-right: 2.5px;
}
.prev_next_news_title {
  position: relative;
  z-index: 1;
  background: rgb(255 255 255/75%);
  padding: 17px 40px;
  border-radius: 100px;
  text-align: center;
  transition: all 0.3s ease;
}
.prev_next_news_holder > div:hover .prev_next_news_title {
  background: rgb(255 255 255/90%);
}
.prev_next_news_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0/0);
  transition: all 0.3s ease;
}
.prev_next_news_holder > div:hover .prev_next_news_overlay {
  background: rgb(0 0 0/45%);
}
.prev_next_news_title {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.prev_next_news_title h3 {
  margin-top: 9px;
  font-size: 15px;
}
.prev_next_news_title span {
  height: 2px;
  width: 55%;
  background: #e53a55;
  border-radius: 100px;
  display: block;
  margin: 8px auto auto;
  transition: all 0.2s ease;
}
.prev_next_news_holder > div:hover span {
  width: 100%;
}
.news_content.content_box {
  margin-top: 85px;
}
.news_page_title_wrapper {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 20px;
}
.news_page_title {
  display: inline-block;
  text-align: center;
}
.news_page_title h1 {
  display: inline-block;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.news_page_title span {
  display: inline-block;
  height: 3px;
  background: #e53a55;
  width: 80%;
  border-radius: 100px;
}
.news_list_item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 60px;
}
.news_list_item_image {
  width: calc(50% - 25px);
}
.news_list_item_image img {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 5px 8px 0 rgb(0 0 0/19%);
}
.news_list_item_text {
  width: 50%;
}
.news_list_item_btn {
  background: #e53a55;
  border-radius: 3px;
  color: #fff;
  width: 100%;
  display: block;
  line-height: 2.5;
  text-align: center;
  text-transform: uppercase;
  font-family: medium;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 2px;
}
.news_list_item_title h3:after {
  content: '';
  height: 2px;
  display: block;
  background: #e53a55;
  width: 100px;
  margin-top: 10px;
  border-radius: 100px;
}
.news_list_item:nth-child(2n) .news_list_item_image {
  order: 2;
  margin-left: 25px;
}
.news_list_item:nth-child(odd) .news_list_item_image {
  margin-right: 25px;
}
.news_list_item:nth-child(2n) .news_list_item_text div,
.news_list_item:nth-child(2n) .news_list_item_text h3,
.news_list_item:nth-child(2n) .news_list_item_text p {
  text-align: right;
}
.news_list_item_title h3 {
  text-transform: uppercase;
  margin-bottom: 12px;
  font-family: medium;
  letter-spacing: 1px;
  font-size: 17px;
}
.news_list_item:nth-child(2n) .news_list_item_title h3:after {
  margin-left: auto;
}
.news_list_item_btn:hover {
  background: #ea4a63;
}
.contact_content {
  display: flex;
  flex-wrap: wrap;
}
.contact_left {
  position: fixed;
}
.contact_left,
.contact_right {
  width: 50%;
  margin-top: 80px;
}
.contact_right .copyrights_footer {
  position: relative;
  margin-top: 25px;
}
.contact_info_wrapper {
  display: flex;
  width: 80%;
  margin: 45px auto auto;
}
.contact_info_social {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.contact_info_social img {
  width: 33px;
  transform: scale(1);
  transition: all 0.3s ease;
}
.contact_info_social a {
  margin-left: 8px;
}
.contact_info_social a:hover img,
.social_network_menu a:hover img {
  transform: scale(1.15);
}
.contact_logo {
  margin-top: auto;
}
a.contact_logo:hover img {
  transform: scale(1);
}
.contact_logo img {
  width: 100% !important;
}
.contact_right {
  margin-left: auto;
}
.contact_info_section_item {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}
.contact_info_section_item a {
  color: #555;
}
.contact_info_section_item span {
  margin-left: 10px;
}
#map {
  height: calc(100vh - 80px);
  width: 100%;
}
.contact_right form {
  max-width: 80%;
  margin: auto;
}
.contact_right form input[type='email'],
.contact_right form input[type='text'] {
  width: 100%;
  border: none;
  line-height: 40px;
  padding: 0 15px;
  color: #555;
}
.contact_right input:focus,
.contact_right textarea:focus,
button:focus {
  outline: none;
}
.contact_right textarea {
  width: 100%;
  height: 150px;
  resize: none;
  color: #767676;
  border: none;
  padding: 10px 15px;
  color: #555;
}
.contact_form_item_label p {
  line-height: 40px;
  background: #eaeaea;
  padding: 0 15px;
  font-size: 15px;
}
.contact_form_item select {
  width: 100%;
  height: 41px;
  border: none;
  text-indent: 12px;
  font-size: 15px;
  color: #484848;
}
.captcha_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.captcha_input label {
  line-height: 40px;
  font-size: 15px;
}
.captcha_input input {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  border: 1px solid #dfdfdf !important;
  width: 200px !important;
}
.form_inputs {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #dfdfdf;
}
.contact_send_btn {
  width: 100%;
  line-height: 3;
  font-size: 15px;
  text-transform: uppercase;
  font-family: medium;
  color: #fff;
  background: #e53a55;
  border: none;
  border-radius: 3px;
  outline: none;
  margin-top: 25px;
}
.contact_send_btn:hover {
  background: #ce1431;
}
.contact_send_btn span {
  vertical-align: middle;
}
.contact_send_btn span:first-child {
  margin-right: 7px;
}
.contact_form_item_half_holder {
  display: flex;
  flex-wrap: wrap;
}
.contact_form_item_half_holder .contact_form_item_half {
  width: calc(50% - 0.5px);
}
.contact_form_item_half:first-child {
  border-right: 1px solid #dfdfdf;
}
.contact_right .select {
  width: 100%;
  height: 40px;
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-bottom: -1px;
}
.contact_right .select ul {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #000;
  list-style-type: none;
}
.contact_right .select ul li {
  padding: 0 20px;
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  transition: all 0.2s ease;
}
.contact_right .select ul li:not(:first-of-type) {
  display: none;
  background: #f1f1f1;
}
.contact_right .select ul li:not(:first-of-type):hover {
  background: #f8f8f8;
}
.select ul li:first-of-type:after {
  content: '';
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: #4c4c4c;
  transform: translateY(25%);
}
.contact_right .select.active ul li {
  display: flex;
}
.contact_right .select.active ul li:first-of-type:after {
  transform: rotate(180deg) translateY(25%);
}
.contact_info_section {
  width: 80%;
  margin: auto;
}
.industries_content {
  position: relative;
  z-index: 1;
  width: 790px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 45px;
  margin: auto;
}
.industries_content_wrapper {
  position: relative;
  background: url(../images/slider-bg.png);
}
.industries_description h3 {
  margin-bottom: 15px;
  font-weight: 500;
  padding-top: 10px;
  font-family: medium;
}
.industries_description {
  max-width: 800px;
  margin: 25px auto;
}
.white_gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: #fff;
  background: linear-gradient(0deg, #fff, #fff 31%, rgb(255 255 255/0));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
.industries_content_industry {
  width: 355px;
  text-align: center;
  margin-bottom: 100px;
}
.industry_slider {
  max-width: 40%;
}
.industry_slider img {
  width: 100%;
  max-width: 360px;
  margin: auto;
}
.industry_slider .slick-next:before,
.industry_slider .slick-prev:before {
  color: #ff6b6b;
}
.industry_slider .slick-next,
.industry_slider .slick-prev {
  width: 20px;
  height: 54px;
}
.industry_slider .slick-next:before {
  content: '';
  min-width: 20px;
  min-height: 36px;
  background: url(../images/next.png);
  background-size: cover;
}
.industry_slider .slick-prev:before {
  content: '';
  min-width: 20px;
  min-height: 36px;
  background: url(../images/prev.png);
  background-size: cover;
}
.industries_content_industry.bakery_ind img {
  height: 199px;
}
.industries_content_industry.food_ind img {
  height: 310px;
  margin-top: -28px;
}
.industries_content_industry h3 {
  text-transform: uppercase;
}
.industries_content_industry h3:after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: #ddd;
  border-radius: 100px;
  margin: 10px auto 12px;
}
.industries_content_industry:nth-child(odd) {
  margin-right: 40px;
}
.industries_content_industry:nth-child(2n) {
  margin-left: 40px;
}
.industries_content_industry a {
  background: #e63a55;
  box-shadow: 0 4px 8px rgb(0 0 0/15%);
  border-radius: 3px;
  width: 100%;
  display: block;
  color: #fff;
  line-height: 40px;
  font-size: 17px;
  font-family: medium;
  letter-spacing: 0.6px;
  margin-top: 20px;
}
.industries_content_industry a:hover {
  background: #ce1431;
}
.industries_wrapper .news_page_title_wrapper {
  text-align: center;
  padding-top: 130px;
  padding-bottom: 0;
}
.industries_wrapper .news_page_title span {
  width: 50%;
}
.industry_cat_content {
  background: url(../images/slider-bg.png);
  background-repeat: no-repeat;
  background-size: 27%;
  background-position: 76% 120px;
}
.industry_cat_content .slick-track {
  display: flex;
}
.industry_cat_content .slick-initialized .slick-slide {
  margin: auto;
}
.industry_cat_first_section {
  padding-top: 145px;
  display: flex;
  align-items: center;
}
.industry_cat_first_section_text {
  width: 60%;
  margin-right: auto;
}
.industry_cat_first_section_text h1 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.industry_cat_first_section_text h1:after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 100px;
  background: #e31838;
  width: 105px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.timeline_holeder {
  background-color: #ec3554;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 800'%3E%3Cg stroke='%23d9d9d9' stroke-width='30.7' stroke-opacity='.02'%3E%3Ccircle fill='%23ec3554' r='1800'/%3E%3Ccircle fill='%23e73251' r='1700'/%3E%3Ccircle fill='%23e2304e' r='1600'/%3E%3Ccircle fill='%23de2d4b' r='1500'/%3E%3Ccircle fill='%23d92b48' r='1400'/%3E%3Ccircle fill='%23d42845' r='1300'/%3E%3Ccircle fill='%23cf2542' r='1200'/%3E%3Ccircle fill='%23ca2240' r='1100'/%3E%3Ccircle fill='%23c6203d' r='1000'/%3E%3Ccircle fill='%23c11d3a' r='900'/%3E%3Ccircle fill='%23bc1a37' r='800'/%3E%3Ccircle fill='%23b81735' r='700'/%3E%3Ccircle fill='%23b31332' r='600'/%3E%3Ccircle fill='%23ae102f' r='500'/%3E%3Ccircle fill='%23aa0c2d' r='400'/%3E%3Ccircle fill='%23a5082a' r='300'/%3E%3Ccircle fill='%23a10428' r='200'/%3E%3Ccircle fill='%239c0025' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.timeline:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 2px;
  top: 0;
  background: #fff;
  z-index: 1;
}
.timeline {
  position: relative;
  margin: 50px auto;
  width: 1000px;
  padding: 50px 0;
}
.timeline ul {
  margin: 0;
  padding: 0;
}
.timeline ul li {
  list-style: none;
  box-sizing: border-box;
  line-height: normal;
  position: relative;
  width: 50%;
  padding: 18px 70px 40px;
}
.timeline ul li .right_content h2 {
  color: #fff;
  padding: 0 2px 10px 0;
  font-size: 20px;
  font-family: medium;
}
.timeline ul li .right_content h2 img {
  width: 40px;
}
.timeline ul li:nth-child(odd) h2:after {
  margin-left: auto;
}
.timeline p {
  width: 200px;
  color: #fff;
}
.timeline ul li:nth-child(odd) p {
  margin-left: auto;
}
.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}
.timeline ul li:nth-child(2n) {
  float: right;
  text-align: left;
  clear: both;
}
.left_content {
  padding-bottom: 20px;
}
.timeline ul li:nth-child(odd):before {
  background: #ffe8ec;
  z-index: 2;
}
.timeline ul li:nth-child(odd):after,
.timeline ul li:nth-child(odd):before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgb(255 236 235/23%);
  right: -9px;
  top: 24px;
}
.timeline ul li:nth-child(odd):after {
  background: transparent;
  z-index: 1;
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
}
.timeline ul li:nth-child(2n):before {
  background: #ffe8ec;
  z-index: 2;
}
.timeline ul li:nth-child(2n):after,
.timeline ul li:nth-child(2n):before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgb(255 236 235/23%);
  left: -7px;
  top: 24px;
}
.timeline ul li:nth-child(2n):after {
  background: transparent;
  transform: scale(2.5);
  z-index: 1;
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
}
@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulsateFont {
  25% {
    pacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pulsateFont {
  25% {
    pacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.timeline ul li:nth-child(odd) .left_content {
  position: absolute;
  top: 12px;
  right: -100px;
  margin: 0;
  padding: 8px 16px;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
}
.timeline h3 {
  font-family: medium;
}
.timeline ul li:nth-child(2n) .left_content {
  position: absolute;
  top: 12px;
  left: -100px;
  margin: 0;
  padding: 8px 16px;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
}
.ps-timeline-sec {
  background: #fff;
}
.ps-timeline-sec,
.ps-timeline-sec .container {
  position: relative;
}
.ps-timeline-sec .container ol.ps-timeline {
  display: flex;
  padding: 0;
  border-top: 2px solid #dcdcdc;
  list-style: none;
  height: 0;
  margin: 350px 0 390px;
}
.ps-timeline-sec .container ol.ps-timeline li {
  float: left;
  width: 25%;
  padding-top: 0;
  position: relative;
}
.ps-timeline-sec .container ol.ps-timeline li span {
  margin-left: -25px;
  border-radius: 50%;
  text-align: center;
  color: #d04242;
  font-size: 25px;
  font-style: normal;
  top: -21px;
  left: 50%;
  content: '';
  position: relative;
  width: 21px;
  line-height: 21px;
  background: #fff;
  box-shadow: 0 0 0 3px rgb(255 236 235/23%);
  z-index: 2;
  padding: 10px;
  display: block;
}
.steps_private_label {
  position: relative;
  padding: 10px 0;
  background-color: #ec3554;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 800'%3E%3Cg stroke='%23d9d9d9' stroke-width='30.7' stroke-opacity='.02'%3E%3Ccircle fill='%23ec3554' r='1800'/%3E%3Ccircle fill='%23e73251' r='1700'/%3E%3Ccircle fill='%23e2304e' r='1600'/%3E%3Ccircle fill='%23de2d4b' r='1500'/%3E%3Ccircle fill='%23d92b48' r='1400'/%3E%3Ccircle fill='%23d42845' r='1300'/%3E%3Ccircle fill='%23cf2542' r='1200'/%3E%3Ccircle fill='%23ca2240' r='1100'/%3E%3Ccircle fill='%23c6203d' r='1000'/%3E%3Ccircle fill='%23c11d3a' r='900'/%3E%3Ccircle fill='%23bc1a37' r='800'/%3E%3Ccircle fill='%23b81735' r='700'/%3E%3Ccircle fill='%23b31332' r='600'/%3E%3Ccircle fill='%23ae102f' r='500'/%3E%3Ccircle fill='%23aa0c2d' r='400'/%3E%3Ccircle fill='%23a5082a' r='300'/%3E%3Ccircle fill='%23a10428' r='200'/%3E%3Ccircle fill='%239c0025' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.ps-timeline-sec .container ol.ps-timeline li span:after {
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgb(255 236 235/23%);
  z-index: 1;
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  left: 3px;
  top: 2px;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
  position: absolute;
  margin-top: 60px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: auto auto 70px;
  text-align: center !important;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-bot {
  width: 100%;
  position: absolute;
  margin: 60px auto auto;
  text-align: center !important;
}
.steps_logo {
  position: absolute;
  bottom: 45px;
  right: 50px;
}
h3.steps_private_label_title {
  color: rgba(255, 150, 150, 0.7607843137254902);
  letter-spacing: 1.2px;
  text-align: center;
  padding-top: 60px;
  font-size: 23px;
  -webkit-animation: pulsateFont 1.5s ease-out;
  animation: pulsateFont 1.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  text-transform: uppercase;
  font-family: medium;
}
.private_label_text_section {
  text-align: center;
  font-size: 17px;
  padding: 100px;
  margin: auto;
}
.private_label_text_section h1 {
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  margin: 0;
}
.private_label_text_section h1:after {
  content: '';
  display: inline-block;
  height: 3px;
  background: #e31838;
  width: 50%;
  margin: auto;
  border-radius: 100px;
}
.private_label_text_section.last_section {
  padding-bottom: 0;
}
.private_label_text_section p:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 1000px) {
  .timeline {
    width: 100%;
  }
}
.popup_wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: rgb(255 255 255/90%);
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.direct_download ul {
  text-align: left;
  padding-left: 30px;
}
.direct_download ul li {
  position: relative;
  line-height: 2.5;
  list-style: none;
}
.direct_download h3 {
  text-align: left;
  margin-bottom: 20px;
  font-weight: 500;
  font-family: Maven Pro, sans-serif;
}
.direct_download ul li :before {
  content: '';
  width: 14px;
  height: 14px;
  opacity: 0.5;
  display: block;
  background: url(../images/logo-bullet.svg);
  background-size: cover;
  margin-top: 6.7px;
  position: absolute;
  left: -25px;
  top: 7px;
}
.direct_download ul li a {
  color: #555;
  font-weight: 400;
}
.popup_content {
  position: relative;
  max-width: 800px;
  background: #fff;
  box-shadow: 0 3px 15px rgb(0 0 0/20%);
  border-radius: 10px;
  padding: 40px 30px;
  box-sizing: border-box;
}
#confirm_msg_popup {
  display: none;
}
.popup_content .close_popup {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 22px;
}
.popup_content .close_popup img {
  width: 100%;
}
.download_popup .popup_content {
  text-align: center;
  margin: 120px auto auto;
}
.direct_download .popup_content {
  width: 500px;
}
.download_popup .form_inputs {
  border-radius: 0;
  overflow: visible;
  border: none;
}
.download_popup .contact_form_item_half:first-child {
  border-right: none;
  margin-right: auto;
}
.download_popup .contact_form_item_half_holder .contact_form_item_half {
  width: 48%;
}
.download_popup .popup_content input[type='email'],
.download_popup .popup_content input[type='text'] {
  width: 100%;
  display: block;
  margin: 20px auto;
  line-height: 40px;
  border: 1px solid #ddd;
  font-size: 15px;
  text-indent: 15px;
  border-radius: 3px;
}
.download_popup .contact_form_item select {
  width: 100%;
  height: 41px;
  text-indent: 12px;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #757575;
}
.download_popup .popup_content textarea {
  width: 100%;
  border-radius: 3px;
  resize: none;
  border: 1px solid #ddd;
  padding: 15px;
  font-size: 15px;
  margin-bottom: 25px;
  height: 115px;
  box-sizing: border-box;
}
.download_popup .buttons_holder button {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  font-weight: 500;
  font-size: 17px;
  line-height: 41px;
  text-align: center;
  width: 230px;
  border: none;
  color: #fff;
}
.download_popup .buttons_holder button:first-child {
  background: #e63a55;
}
.download_popup .buttons_holder button:last-child {
  background: #858585;
}
.downlaod_section_wrapper {
  display: flex;
  margin: auto;
  padding-top: 120px;
  align-items: center;
}
.download_buttons a {
  display: block;
  background: #f9f9f9;
  box-shadow: 0 3px 6px rgb(0 0 0/15%);
  border-radius: 3px;
  line-height: 45px;
  margin-bottom: 20px;
  text-align: center;
  color: #555;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.download_section_left .title h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #555;
  margin-bottom: 10px;
}
.download_section_left .title span {
  width: 65%;
  height: 3px;
  background: #e53a55;
  display: block;
  border-radius: 1000px;
  margin-bottom: 30px;
}
.download_section_left {
  margin-right: auto;
  width: 320px;
}
.industries_sub_cat_holder {
  position: relative;
  padding: 50px 0;
}
.industries_sub_cat_holder:first-child {
  padding-top: 0;
  margin-top: -13px;
}
.industries_sub_cat_holder:first-child,
.industries_sub_cat_holder:nth-child(3) {
  z-index: 1;
  position: relative;
  background-size: cover !important;
  background-position: top !important;
  color: #fff;
}
.industries_sub_cat_holder:first-child:before {
  display: none !important;
}
.industries_sub_cat_holder:before {
  content: '';
  display: block;
  width: 70px;
  height: 70px;
  background: url(../images/button-divider.svg);
  background-size: cover;
  position: absolute;
  top: -37px;
  left: 50%;
  margin-left: -31px;
  z-index: 3;
}
.industries_sub_cat_holder:first-child {
  background: url(../images/singl-bg1.svg);
}
.industries_sub_cat_holder:nth-child(3) {
  background: url(../images/singl-bg3.svg);
}
.industries_sub_cat_holder_1 h3:after,
.industries_sub_cat_holder_3 h3:after {
  background: #ff3e5c;
}
.industries_sub_cat_holder_1 a,
.industries_sub_cat_holder_3 a {
  background: #fff;
  color: #e31838;
}
.industries_sub_cat_holder_1 a:hover,
.industries_sub_cat_holder_3 a:hover {
  background: #d00f0f;
  color: #fff;
}
.content_od_fruit_wrapper {
  padding-top: 120px;
}
.od_fruit_first_section,
.od_fruit_second_section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.od_fruit_first_section_text,
.od_fruit_second_section_text {
  width: 47%;
  margin-right: auto;
}
.od_fruit_first_section_text .title {
  display: inline-block;
}
.od_fruit_slider {
  width: 50%;
}
.od_fruit_slider img {
  width: 100%;
}
.od_fruit_banner_holder img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.od_fruit_second_section .od_fruit_second_section_text {
  order: 2;
  margin-right: 0;
  margin-left: auto;
}
.od_fruit_second_section .od_fruit_second_section_text .text {
  margin-bottom: 20px;
}
.od_fruit_first_section_text .title h1 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.od_fruit_third_section {
  margin-top: 45px;
}
.od_fruit_third_section .od_list {
  margin-top: 10px;
}
.od_fruit_third_section .title {
  margin-bottom: 15px;
}
.od_list li {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 12px;
  line-height: 1.4;
}
.od_list li span {
  padding-left: 15px;
  width: calc(100% - 30px);
}
.od_list li:before {
  content: '';
  width: 14px;
  height: 14px;
  opacity: 0.5;
  display: block;
  background: url(../images/logo-bullet.svg);
  background-size: cover;
  margin-top: 6.7px;
}
.od_list li span {
  line-height: 1.5;
}
.od_fruit_first_section_text .title span {
  height: 3px;
  width: 60%;
  background: #e31838;
  display: block;
  border-radius: 100px;
  margin-top: 10px;
  margin-bottom: 12px;
}
.od_fruit_first_section_text .text {
  margin-bottom: 20px;
}
.od_fruit_first_section_text .text h3 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
  line-height: 1.4;
  font-family: medium;
}
.od_fruit_first_section_text .text:last-child {
  margin-bottom: 0;
}
.od_fruit_banner_holder {
  position: relative;
  margin: 50px 0;
  max-height: 500px;
  overflow: hidden;
}
.od_fruit_banner_gradient {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.banner_logo {
  width: 200px !important;
  height: auto !important;
  z-index: 9;
  position: absolute;
  right: 50%;
  top: 50%;
  margin-right: -100px;
  margin-top: -100px;
}
.industry_products_wrapper {
  margin-top: -15px;
  z-index: 1;
  position: relative;
}
.industry_product {
  text-align: center;
}
.industry_cat_first_section_img {
  width: 40%;
  text-align: right;
}
.industry_cat_first_section_img img {
  width: 100%;
  max-width: 330px;
}
.industry_product h3 {
  display: inline-block;
  margin-bottom: 20px;
}
.industry_product h3:after {
  content: '';
  display: inline-block;
  height: 2px;
  border-radius: 100px;
  width: 60%;
}
.industry_product:nth-child(2n) h3:after {
  background: #e31838;
}
.industry_product:nth-child(odd) h3:after {
  background: #fff;
}
.industry_product:first-child {
  margin-top: 0;
  z-index: 0;
}
.industry_product:last-of-type .industry_product_divider2 {
  display: none;
}
.industry_products_wrapper .industry_product:last-of-type {
  padding-bottom: 0 !important;
}
.industry_product:nth-child(odd) {
  background: #e31838;
  color: #fff;
  padding: 30px 0 117px !important;
  z-index: 1;
  position: relative;
}
.industry_product_divider2 {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
}
.industry_product:nth-child(2n) {
  color: #555;
  padding: 105px 0 !important;
  margin-top: -205px;
  z-index: 1;
  position: relative;
}
.industry_product:nth-child(2n) h3 {
  padding-top: 30px;
  color: #e31937;
}
.industry_product_divider {
  width: 100%;
}
.industry_product:nth-child(2n) .industry_product_content {
  background: #fff;
  z-index: 1;
  position: relative;
  margin-top: -30px;
}
.products_last_dividier {
  width: 100%;
}
.taste_center_content {
  margin-top: 120px;
}
.taste_center_content .img_section {
  margin: 30px 0;
}
.taste_center_content h1,
.taste_center_content h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.img_section {
  display: flex;
}
.img_section .img_holder {
  width: 50%;
}
.img_section .img_holder:first-child {
  margin-right: 10px;
}
.img_section .img_holder:last-child {
  margin-left: 10px;
}
.img_section .img_holder img {
  width: 100%;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0 10px 10px 0 rgb(0 0 0/10%);
}
.quality_page_content_wrapper {
  margin-top: 150px;
}
.quality_page_notes {
  margin-top: 10px;
}
.quality_page_notes a {
  color: #f44336;
  text-decoration: underline;
}
.quality_page__section {
  display: flex;
  align-items: center;
}
.quality_page_content_wrapper h1 {
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.quality_page__section h1 {
  display: inline-block;
  margin-bottom: 0;
  line-height: 1;
}
.quality_page_content_wrapper .title {
  display: inline-block;
}
.quality_page_content_wrapper .title span {
  display: inline-block;
  height: 3px;
  background: #e53a55;
  width: 80%;
  border-radius: 100px;
  margin-bottom: 15px;
  margin-top: 8px;
}
.quality_page__section .quality_page_photo {
  width: 53%;
}
.quality_page__section .quality_page_photo img {
  width: 100%;
  margin-top: -35px;
  box-shadow: 0 4px 12px 5px rgb(0 0 0/12%);
  border-radius: 16px;
}
.quality_page__section .text {
  width: 47%;
  margin-bottom: 3%;
}
.quality_page__section:nth-child(odd) .text h1,
.quality_page__section:nth-child(odd) .text p {
  margin-right: 20px;
}
.quality_page__section:nth-child(2n) .text p {
  margin-left: 20px;
}
#session_messages {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 101;
}
#session_messages .success_messages {
  border-radius: 5px;
  border: 1px solid #28a745;
  color: #28a745;
  background-color: rgba(0, 255, 0, 0.3);
  padding: 8px 12px;
}
#session_messages .error_messages {
  border-radius: 5px;
  border: 1px solid #ce1431;
  color: #ce1431;
  background-color: rgba(255, 0, 0, 0.3);
  padding: 8px 12px;
}
@media screen and (max-width: 1590px) {
  .industry_product:nth-child(2n) {
    margin-top: -225px;
  }
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -24px;
  }
}
@media screen and (max-width: 1530px) {
  .industry_product_divider2 {
    bottom: -20px;
  }
}
@media screen and (max-width: 1400px) {
  .industry_cat_content {
    background: url(../images/slider-bg.png);
    background-repeat: no-repeat;
    background-size: 34%;
    background-position: 90% 120px;
  }
}
@media screen and (max-width: 1300px) {
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -20px;
  }
}
@media screen and (max-width: 1240px) {
  .about_us_home_text {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .content_box {
    max-width: 90%;
  }
}
@media screen and (max-width: 1200px) {
  .content_box {
    max-width: 90%;
  }
  .quality_page__section .quality_page_photo img {
    margin-top: -20px;
  }
}
@media screen and (max-width: 1100px) {
  .quality_page__section {
    flex-wrap: wrap;
  }
  .quality_page__section .text {
    width: 100%;
  }
  .quality_page__section .quality_page_photo {
    display: block;
    width: 60%;
    margin: 30px auto;
  }
  .quality_page__section:nth-child(2n) .text p {
    margin-left: 0;
  }
  .quality_page__section:nth-child(odd) .text p {
    margin-right: 0;
  }
  .quality_page__section:nth-child(2n) .quality_page_photo {
    order: 2;
  }
  .main_slider .slide_content {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .home_main_slider img {
    margin-right: auto;
    max-width: 355px;
    margin-left: auto;
  }
  .home_main_slider_text {
    width: 100%;
    order: 2;
    padding-left: 0;
    text-align: center;
    flex-wrap: wrap;
  }
  .about_us_home .content_box {
    flex-wrap: wrap;
  }
  .about_us_home_text {
    max-width: 80%;
    text-align: center;
    margin: auto auto 30px;
  }
  .about_us_home_video {
    margin: auto;
  }
  .about_us_home a {
    display: none;
  }
  .more_about_us_home_btn2 {
    width: 100%;
    text-align: center;
  }
  .more_about_us_home_btn2 a {
    display: inline-block !important;
    background: #f93c5a;
    margin-top: 30px;
    color: #fff;
    letter-spacing: 0.5px;
    box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.18823529411764706);
    border: 2px solid;
  }
  .private_labiling_home_text {
    text-align: center;
    margin: auto;
  }
}
@media screen and (max-width: 1164px) {
  .industry_product_divider2 {
    bottom: -15px;
  }
  .about_us_first_section .content_box {
    flex-wrap: wrap;
    text-align: center;
  }
  .about_us_first_section .left,
  .about_us_first_section .right {
    margin: auto;
  }
}
@media screen and (max-width: 1075px) {
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -16px;
  }

  .contact_left,
  .contact_right {
    width: 100%;
  }
  .contact_right {
    margin-top: 50px;
  }
  .contact_left {
    position: static;
  }
  .contact_info_wrapper,
  .contact_right form {
    width: 90%;
    max-width: 90%;
  }
  #map {
    height: 350px;
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .divider_1_about {
    display: none;
  }
  .industries_content_wrapper {
    background-position: bottom;
  }
  .about_us_history_section.content_box {
    text-align: center;
  }
  .od_fruit_first_section_text,
  .od_fruit_second_section_text {
    width: 100%;
    margin-right: auto;
    text-align: center;
  }
  ul.od_list {
    text-align: left;
  }
  .slick-dotted.slick-slider {
    margin: auto;
  }
  .od_fruit_first_section_text .title span {
    height: 2px;
    margin: auto;
    margin-top: 10px !important;
    margin-bottom: 16px !important;
  }
  .od_fruit_second_section_text {
    margin-top: 50px;
  }
  .od_fruit_slider {
    width: 70%;
  }
  .od_fruit_second_section .od_fruit_slider {
    order: 2;
  }
}
@media screen and (max-width: 1000px) {
  .home_counter {
    height: 358px;
  }
  .industry_cat_content {
    background: url(../images/slider-bg.png);
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 95% 120px;
  }
  .download_section_left {
    width: 320px;
    margin: auto;
    text-align: center;
  }
  .download_section_right {
    display: none;
  }
  .download_section_left .title span {
    margin: auto auto 30px;
  }
  .counder_holder {
    width: 413px;
    z-index: 1;
  }
  .home_counter .counter_item {
    width: 190px;
    height: 190px;
  }
  .home_counter .counter_item:first-child,
  .home_counter .counter_item:nth-child(4) {
    border-radius: 3px 80px;
  }
  .home_counter .counter_item:nth-child(2),
  .home_counter .counter_item:nth-child(3) {
    border-radius: 80px 3px;
  }
  .home_counter .counter_item {
    border: 3px solid #fff;
  }
  .counter_item_content > div,
  .counter_item_content p {
    width: 90%;
    text-align: center;
    margin: auto;
    line-height: 1.2;
  }
  .counter_item_number {
    font-size: 28px;
  }
}
@media screen and (max-width: 920px) {
  .banner_image {
    max-width: 90% !important;
  }

  .products_slider_home_content .slick-slide {
    display: block;
  }
  .products_slider_home img {
    margin: auto;
    margin-bottom: 50px;
  }
  .industry_product_divider2 {
    bottom: -13px;
  }
  .industries_content_industry {
    width: 100%;
  }
  .industries_content {
    width: 360px;
  }
  .industries_content_industry:nth-child(odd) {
    margin-right: auto;
  }
  .industries_content_industry:nth-child(2n) {
    margin-left: auto;
  }
}
@media screen and (max-width: 900px) {
  .news_list_item {
    display: block;
  }
  .news_list_item:nth-child(odd) .news_list_item_image {
    margin-right: auto;
    margin: auto;
    margin-bottom: 25px;
  }
  .news_list_item:nth-child(2n) .news_list_item_text div,
  .news_list_item:nth-child(2n) .news_list_item_text h3,
  .news_list_item:nth-child(2n) .news_list_item_text p {
    text-align: center;
  }
  .news_list_item_title h3:after {
    display: none;
  }
  .news_list_item:nth-child(2n) .news_list_item_image {
    margin-left: auto;
    margin: auto;
    margin-bottom: 25px;
  }
  .news_list_item_image {
    width: auto;
    max-width: 400px;
  }
  .news_list_item_text {
    width: auto;
    max-width: 400px;
    margin: auto;
    text-align: center;
  }
  .industry_slider.slick-dotted.slick-slider {
    margin: auto;
    margin-bottom: 30px;
  }
  .popup_content {
    max-width: 90%;
  }
  .industry_product_divider2 {
    bottom: -13px;
  }
  .industry_cat_first_section {
    flex-wrap: wrap;
  }
  .industry_cat_first_section_img {
    width: 100%;
    text-align: center;
  }
  .industry_cat_first_section_img img {
    width: 100%;
    max-width: 300px;
  }
  .industry_cat_first_section_text {
    width: 100%;
    margin-right: auto;
    text-align: center;
  }
  .industry_cat_first_section_text h1:after {
    margin: 10px auto 15px;
  }
  .industry_cat_first_section_text {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 833px) {
  .od_fruit_banner_holder {
    display: none;
  }
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -14px;
  }
  .news_slider_home_content .slide {
    padding: 95px 0;
  }
  .quality_page__section .quality_page_photo {
    width: 100%;
  }
}
@media screen and (max-width: 735px) {
  .private_label_text_section {
    padding: 55px 0;
  }
  .private_label_text_section h1:after {
    height: 2px;
  }
  h3.steps_private_label_title {
    font-size: 19px;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    display: flex;
    padding: 0;
    border-top: 2px solid #dcdcdc;
    list-style: none;
    height: 0;
    margin: 200px auto 210px;
    width: 90%;
  }
  .private_label_text_section h1 {
    margin-bottom: 20px;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    font-size: 15px;
    width: 13px;
    height: 13px;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    font-style: 9px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    margin: 34px auto auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    margin: auto auto 45px;
  }
  .od_fruit_slider {
    width: 100%;
  }
  .industry_product_divider2 {
    bottom: -10px;
  }
  .img_section {
    display: block;
  }
  .img_section .img_holder {
    width: 100%;
  }
  .img_section .img_holder:last-child {
    margin-left: auto;
  }
  .social_networks_footer li a {
    display: block;
    text-align: center;
  }
  .social_networks_footer img {
    display: block;
    margin: auto;
    margin-bottom: 8px;
  }
  .industry_product:first-child {
    margin-top: 8px;
  }
  .social_networks_footer li a span {
    margin-left: auto;
  }
  .about_us_home_video {
    margin: auto;
    width: 100%;
  }
  .about_us_home iframe {
    width: 100% !important;
  }
  .footer_main .footer_items_holder {
    display: block;
  }
  .footer_item {
    text-align: center;
    max-width: 180px;
    margin: 25px auto auto;
    padding-top: 25px;
  }
  .footer_item:first-of-type {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  .footer_item h3 {
    display: inline-block;
  }
  .footer_item h3:after {
    width: 80%;
    margin: 9px auto auto;
  }
  .footer_main {
    position: relative;
    background: url(../images/bg-footer2.png);
  }
  .footer_logo {
    padding-bottom: 82px;
    padding-top: 0;
  }
  .footer_logo img {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 670px) {
  .od_fruit_first_section_text .title h1 {
    font-size: 16px;
  }
  .contact_info_wrapper {
    display: block;
  }
  .contact_info_section {
    width: auto;
  }
  .contact_info_social {
    display: block;
  }
  .contact_logo img {
    max-width: 150px;
    margin: auto;
    display: block;
  }
  .contact_info_section_item {
    display: block;
    text-align: center;
  }
  .contact_info_section_item img {
    margin-bottom: 10px;
  }
  .contact_logo img {
    margin-top: 30px;
  }
  .contact_info_section_item span {
    display: block;
    text-align: center;
  }
  .contact_info_social {
    text-align: center;
  }
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -12px;
  }
  .home_main_slider img {
    width: 300px;
  }
  .private_labiling_home_text {
    max-width: 90%;
    text-align: center;
    margin: auto;
  }
  .private_labiling_home_text a {
    box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.18823529411764706);
    border: 2px solid;
  }
  .news_slider_home_content .slide p {
    width: 90%;
  }
  .center_title {
    margin-bottom: 11px;
  }
  .center_title h1,
  .center_title h2,
  .center_title h3,
  .center_title h4,
  .center_title h5,
  .center_title h6 {
    font-size: 18px;
  }
  .timeline ul li:nth-child(odd) p {
    width: auto;
  }
  .timeline p {
    font-size: 14px;
  }
}
@media screen and (max-width: 650px) {
  .about_us_first_section .right {
    width: 90%;
    margin: auto !important;
  }
  .about_us_first_section iframe {
    width: 100%;
    margin: auto;
  }
  .timeline ul li:nth-child(odd) {
    padding-right: 43px;
  }
  .timeline ul li:nth-child(2n) {
    padding-left: 43px;
  }
}
@media screen and (max-width: 600px) {
  .industry_product_divider2 {
    bottom: -8px;
  }
  .download_popup .buttons_holder button:first-child {
    margin-bottom: 10px;
  }
  .download_popup .contact_form_item_half_holder .contact_form_item_half {
    width: 100%;
  }
  .download_popup .contact_form_item select,
  .download_popup .popup_content input[type='email'],
  .download_popup .popup_content input[type='text'],
  .download_popup .popup_content textarea {
    margin: 10px auto;
  }
  .timeline ul li:nth-child(2n) {
    padding-right: 20px;
  }
  .timeline ul li {
    box-sizing: content-box;
  }
  .timeline ul li:nth-child(2n),
  .timeline ul li:nth-child(odd) {
    width: 50%;
    padding: 0;
  }
  .timeline ul li:nth-child(odd) .right_content {
    margin-right: 40px;
  }
  .timeline ul li:nth-child(2n) .right_content {
    margin-left: 40px;
  }
  .timeline p {
    max-width: 150px;
  }
}
@media screen and (max-width: 586px) {
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -11px;
  }
  .industries_sub_cat_wrapper {
    margin-top: 7px;
  }
  .industries_content {
    width: 90%;
  }
  .home_counter {
    height: 305px;
  }
  .counder_holder {
    width: 363px;
    z-index: 1;
  }
  .news_slider_home_content .slick-next,
  .news_slider_home_content .slick-prev {
    display: none !important;
  }
  .industries_content_industry img {
    max-width: 300px;
  }
  .home_counter .counter_item {
    width: 165px;
    height: 165px;
  }
  .news_slider_home_content .slick-dots {
    bottom: 45px;
    text-align: center;
    padding-left: 0;
  }
  .counter_item_number {
    font-size: 27px;
  }
  .counter_item_icon img {
    width: 40px;
  }
  .home_counter .counter_item:nth-child(2),
  .home_counter .counter_item:nth-child(3) {
    border-radius: 65px 3px;
  }
  .home_counter .counter_item:first-child,
  .home_counter .counter_item:nth-child(4) {
    border-radius: 3px 65px;
  }
  .main_slider {
    position: relative;
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .home_main_slider .slick-dots {
    text-align: center;
    bottom: -10px;
  }
  .about_us_home iframe {
    height: 235px;
  }
  .menu_icon img {
    width: 35px;
  }
  .langs_header {
    margin-right: 20px;
  }
  .langs_header img {
    width: 20px;
  }
  .langs_header a:first-child {
    margin-right: 7px;
  }
  .logo_header {
    width: 135px;
  }
  .logo_header img {
    width: 100%;
  }
}
@media screen and (max-width: 488px) {
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -10px;
  }
  .timeline p {
    max-width: 100px;
    font-size: 14px;
    line-height: 1.2;
  }
  .timeline ul li .right_content h2 img {
    width: 32px;
  }
  .timeline ul li {
    padding-top: 20px !important;
  }
  .industry_product:nth-child(2n) .industry_product_content {
    margin-top: -9px;
  }
  .industry_product_divider2 {
    bottom: -6px;
  }
  .about_us_first_section iframe {
    height: 240px;
  }
  .about_us_text .content_box p {
    font-size: 15px;
  }
  .about_us_text .content_box img {
    margin-bottom: 15px;
  }
  .about_us_video_logo_holder img {
    width: 105px;
  }
  .industries_content_industry img,
  .industry_cat_first_section_img img {
    max-width: 270px;
  }
}
@media screen and (max-width: 325px) {
  .home_counter .counter_item {
    width: 135px;
    height: 135px;
  }
  .counder_holder {
    width: 303px;
  }
  .home_counter {
    height: 260px;
  }
  p.counter_item_title {
    font-size: 12px;
  }
  p.counter_item_number {
    font-size: 22px;
  }
  .home_counter .counter_item {
    box-shadow: 2px 4px 5px 2px rgb(0 0 0/15%);
  }
  .slide_content_news_home .center_title h2 {
    width: 90%;
    line-height: 1.2;
    font-size: 16px;
  }
  .slide_content_news_home .center_title h2:after {
    display: none;
  }
  .about_us_home_text h3,
  .private_labiling_home_text,
  p {
    font-size: 15px;
  }
  .main_slider {
    padding-bottom: 100px;
  }
  .home_main_slider .slick-dots {
    bottom: -60px;
  }
}

/*# sourceMappingURL=style.css.map*/

@media screen and (max-height: 670px) {
  .haeder_menu a {
    line-height: 48px;
    font-size: 15px;
  }
  #header .social_network_menu {
    bottom: 65px;
  }
}

@media screen and (max-height: 620px) {
  .haeder_menu_content {
    margin-top: 45px;
  }
  .haeder_menu .close_icon {
    top: 13px;
  }
  .haeder_menu .wave_img {
    display: none;
  }
  #header .social_network_menu {
    bottom: 25px;
  }
}

@media screen and (max-height: 600px) {
}

/* .anniversary_section {
  background-image: url('../images/banner-anniversary.jpg');
  background-image: url('../images/banner-gulfood.png');
  font-family: 'Maven Pro', sans-serif;
  line-height: 1.6;
  color: #fff;
  border-bottom: 3px solid #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
} */

.anniversary_section {
    /* background-image: linear-gradient(0deg, #380921, transparent),url('../images/banner-trade-fair.jpg'); */
    background: radial-gradient(50% 50% at 50% 50%,#e23a55 0,#e31838 100%);
    font-family: Maven Pro,sans-serif;
    line-height: 1.6;
    color: #fff;
    /* border-bottom: 3px solid #fff; */
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-position: 50%; */
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
}

.banner_image {
    max-width: 900px;
    margin-top: 115px;
    border-radius: 60px 5px;
    box-shadow: 5px 0px 7px 5px #0000000f;
}

.annivesary_text {
  max-width: 90%;
  margin: auto;
  background: transparent;
  padding: 40px 40px;
  border-radius: 60px 7px;
  border: 3px solid #ffffff2e;
  box-shadow: 0 20px 20px #00000030;
  line-height: 1.8;
  font-size: 16px;
  margin-top: 50px;
}
@media all and (max-width: 620px) {
  .anniversary_section {
    /* background-image: url('../images/mob-banner.jpg'); */
    background: radial-gradient(50% 50% at 50% 50%,#e23a55 0,#e31838 100%);
  }
  .annivesary_text {
    padding-top: 100px;
    max-width: 100%;
    margin: auto;
    background: transparent;
    border-radius: 0;
    border: none;
    box-shadow: none;
    line-height: 1.8;
    font-size: 16px;
  }
  .annivesary_text h2 {
    margin-bottom: 15px;
  }
}
